<template>
  <form ref="form">
    <div class="modal-card" style="width: 400px">
      <section class="modal-card-body">
        <b-field :label="$t('comment')">
          <b-input
            v-model="rejectComment"
            type="textarea"
            required
          />
        </b-field>
      </section>
      <footer class="modal-card-foot">
        <b-button
          size="is-medium"
          :loading="isLoadingReject"
          class="is-pulled-right is-fullwidth"
          type="is-danger"
          @click="rejectRequest"
        >
          {{ buttonText }}
        </b-button>
      </footer>
    </div>
  </form>
</template>

<script>
export default {
  name: 'RejectModal',
  props: {
    id: {
      type: Number,
      default: null
    },
    buttonText: {
      type: String,
      default: 'Reject'
    }
  },
  data () {
    return {
      rejectComment: '',
      isLoadingReject: false,
    }
  },
  methods: {
    rejectRequest () {
      if (!this.$refs.form.checkValidity()) {
        this.$refs.form.reportValidity();
        return false;
      }

      this.isLoadingReject = true

      this.$http.post('reject-request', {
        id: this.id,
        comment: this.rejectComment,
      }).then(r => {
        if (r.data.success) {
          this.isLoadingReject = false

          this.$buefy.toast.open({
            message: this.$t('canceled'),
            type: 'is-warning',
            queue: false
          });
          this.$parent.$parent.goBack();
          this.$store.commit('boardRefresh', true)
          // this.$router.go(-1) // back to transfer lists
        }
      })
    }
  }
}
</script>
